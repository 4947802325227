import React, { useEffect, useState } from 'react';
import { Drawer, Button, Select, DatePicker, Space } from 'antd';

const { Option } = Select;
const dateFormat = ['YYYY-MM-DD', 'YYYY-MM']
var moment = require('moment');
// 列表查询 form

const formatSelectedDate = (dayOrMonth, momentObj) => {
    // 格式化选中的日期
    const selectedDateFormat = dateFormat[dayOrMonth]
    return momentObj.format(selectedDateFormat)
}

const PurchaseRecordsQueryDrawerForm = ({ visible, setVisible, queryHandle }) => {

    const [dayOrMonth, setDayOrMonth] = useState(0);    // 0:day, 1:month
    const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));

    useEffect(() => {
        setSelectedDate(formatSelectedDate(dayOrMonth, moment(selectedDate)))
    }, [dayOrMonth])

    const queryDayOrMonth = (val) => {
        // 选择按日或月统计
        setDayOrMonth(val);
    }

    const dateOnChange = (momentObj) => {
        setSelectedDate(formatSelectedDate(dayOrMonth, momentObj));
    }

    const onFinish = () => {
        // 提交查询 根据具体情况更新 fromObj
        console.log(selectedDate);
        queryHandle({'add_time': selectedDate});
        setVisible(0);
    };

    return (
        <>
            <Drawer
                title="请选择销售日期"
                height="300px"
                placement="top"
                onClose={() => setVisible(0)}
                visible={visible === 2}
                bodyStyle={{ paddingBottom: 20 }}
                footer={<Button style={{margin: '0 2rem'}} type="primary" onClick={onFinish}>提交</Button>}
            >
                <Space direction="vertical">
                    <Select defaultValue={0} style={{ width: 120 }} onChange={queryDayOrMonth}>
                        <Option value={0}>按日统计</Option>
                        <Option value={1}>按月统计</Option>
                    </Select>

                    {dayOrMonth === 0
                        ? <DatePicker value={moment(selectedDate)} size="large" onSelect={dateOnChange} />
                        : <DatePicker value={moment(selectedDate)} size="large" onSelect={dateOnChange} picker="month" />}
                </Space>
            </Drawer>
        </>
    );
};

PurchaseRecordsQueryDrawerForm.defaultProps = {
    visible: false,
    setVisible: (booleanValue) => { console.log(booleanValue); },
    queryHandle: (queryParamsObj) => { console.log(queryParamsObj); }
};

export default PurchaseRecordsQueryDrawerForm;
