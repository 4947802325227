import React, { useState, useEffect } from 'react';
// import { navigate, Link } from 'gatsby';
import { Button, Row, Col, Space, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import PurchaseRecordsQueryParams from './purchase_records_query_params_display';
import PurchaseRecordsQueryDrawerForm from './purchase_records_query_form';
import PurchaseRecordsStatisticList from './purchase_records_statistic_list';
import CollectStatistic from './modal_collect_statistic';
import { PurchaseRecordsRequestGet } from './purchase_records_requests'
// PurchaseRecords 入口组件

const PurchaseRecordsComponent = () => {
    const [purchaseRecordsData, setPurchaseRecordsData] = useState([]);
    const [reqParams, setReqParams] = useState({'add_time': '2020-10'})

    const [visible, setVisible] = useState(0);

    const [requestCount, setRequestCount] = useState(0);

    const [requesting, setRequesting] = useState(false);

    useEffect(() => {
        if (requesting) {
            console.log('requesting...');
            setRequesting(false);
            setRequestCount(requestCount + 1);
        }
    }, [requesting]);
    
    const queryHandle = (requestParams) => {
        // 查询操作
        setReqParams({ ...requestParams });
        setRequesting(true);
        console.log('search...');
    };

    const responseDataHandle = (respObj) => {
        // 请求结果
        setPurchaseRecordsData(respObj.results)
    }

    return (
        <div>
            <Row>
                <Col span={14}>
                    <Space size="middle">
                        <h5>Purchase Records Statistic(订单统计)</h5>
                        {/* <Tooltip title="刷新">
                            <Button onClick={initReloadList} shape="circle" icon={<ReloadOutlined />}></Button>
                        </Tooltip> */}
                        <Tooltip title="选择日期">
                            <Button onClick={() => setVisible(2)} shape="circle" icon={<SearchOutlined />}></Button>
                        </Tooltip>
                    </Space>
                </Col>
                <Col span={10}>
                    <div style={{ width: '100%', height: 'auto' }}>
                        <Button style={{ float: 'right' }} type="link" onClick={() => setVisible(1)}>汇总</Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}><PurchaseRecordsQueryParams paramsObj={reqParams}></PurchaseRecordsQueryParams></Col>
            </Row>
            <PurchaseRecordsQueryDrawerForm
                visible={visible}
                setVisible={setVisible}
                queryHandle={queryHandle}
            ></PurchaseRecordsQueryDrawerForm>

            <PurchaseRecordsRequestGet
                requestCount={requestCount}
                reqParams={reqParams}
                responseDataHandle={responseDataHandle}
            ></PurchaseRecordsRequestGet>

            <PurchaseRecordsStatisticList
                purchaseRecords={purchaseRecordsData}
                queryFactor={reqParams}
            ></PurchaseRecordsStatisticList>

            <CollectStatistic
                visible={visible}
                setVisible={setVisible}
                purchaseRecords={purchaseRecordsData}
                queryFactor={reqParams}
            ></CollectStatistic>
        </div>
    )
};

export default PurchaseRecordsComponent;
