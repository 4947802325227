import React, { useState, useEffect } from 'react';
import { Modal, Divider } from 'antd';

// purchase_records 汇总

const CollectStatistic = ({ visible, setVisible, purchaseRecords, queryFactor }) => {
    const [amountObj, setAmountObj] = useState({
        totalAmount: 0,
        totalCostAmount: 0,
        recordsCounts: 0,
        taobaoAmount: 0,
        wxAmount: 0,
        alipayAmount: 0,
        cardAmount: 0,
        moneyAmount: 0,
        unknownPayMethodAmount: 0
    })

    const [statisticDate, setStatisticDate] = useState('')

    const statisticAmounts = () => {
        // 统计金额
        // console.log(purchaseRecords)
        let currentTotalAmount = 0
        let totalCostAmount = 0
        let currentRecordsCounts = 0
        let currentTaobaoAmount = 0
        let currentWxAmount = 0
        let currentAlipayAmount = 0
        let currentMoneyAmount = 0
        let currentCardAmount = 0
        let unknownPayMethodAmount = 0
        for (let index = 0; index < purchaseRecords.length; index++) {
            const element = purchaseRecords[index];
            currentRecordsCounts = currentRecordsCounts + 1
            const currentTotalCostAmount = element.products_cost ? parseFloat(element.products_cost) : 0
            totalCostAmount = totalCostAmount + currentTotalCostAmount
            
            const currentSellAmount = element.purchase_amount ? parseFloat(element.purchase_amount) : 0
            currentTotalAmount = currentTotalAmount + currentSellAmount
            
            currentTaobaoAmount = element.sale_platform === 2 ? currentTaobaoAmount + currentSellAmount : currentTaobaoAmount
            currentWxAmount = element.pay_platform === 1 ? currentWxAmount + currentSellAmount : currentWxAmount
            currentAlipayAmount = element.pay_platform === 2 ? currentAlipayAmount + currentSellAmount : currentAlipayAmount
            currentCardAmount = element.pay_platform === 3 ? currentCardAmount + currentSellAmount : currentCardAmount
            currentMoneyAmount = element.pay_platform === 4 ? currentMoneyAmount + currentSellAmount : currentMoneyAmount
            unknownPayMethodAmount = element.pay_platform === 0 ? unknownPayMethodAmount + currentSellAmount : unknownPayMethodAmount
        }
        setAmountObj({
            totalAmount: currentTotalAmount,
            totalCostAmount: totalCostAmount,
            recordsCounts: currentRecordsCounts,
            taobaoAmount: currentTaobaoAmount,
            wxAmount: currentWxAmount,
            alipayAmount: currentAlipayAmount,
            cardAmount: currentCardAmount,
            moneyAmount: currentMoneyAmount,
            unknownPayMethodAmount: unknownPayMethodAmount,
        })
    }
  
    useEffect(() => {
        if (visible === 1) {
            // 显示时赋值
            statisticAmounts()
        }
    }, [visible]);

    useEffect(() => {
        const sdate = queryFactor.add_time ? queryFactor.add_time : ''
        setStatisticDate(sdate);
    }, [queryFactor])

    return (
        <Modal visible={visible === 1}
            title={`${statisticDate} 销售汇总`}
            closable={false}
            onOk={() => setVisible(0)}
            onCancel={() => setVisible(0)}
            width="80%"
        >
            <p>总销售金额: {amountObj.totalAmount}</p>
            <p>总成本: {amountObj.totalCostAmount}</p>
            <p>订单总数量: {amountObj.recordsCounts}</p>
            <Divider />
            <p>淘宝订单总金额: {amountObj.taobaoAmount}</p>
            <Divider />
            <p>微信收款总金额: {amountObj.wxAmount}</p>
            <p>支付宝收款总金额: {amountObj.alipayAmount}</p>
            <p>刷卡总金额: {amountObj.cardAmount}</p>
            <p>现金总金额: {amountObj.moneyAmount}</p>
            <p>未知收款方式总金额: {amountObj.unknownPayMethodAmount}</p>
        </Modal>
    )
}

CollectStatistic.defaultProps = {
    visible: 0,
    setVisible: (val) => console.log(val),
    purchaseRecords: [],
    queryFactor: {}
}

export default CollectStatistic;
