import React from "react";

import SiteLayout from '../components/site_layout';
import SEO from "../components/seo";

import PurchaseRecordsComponent from '../components/statistic_pages/purchase_records';

const StatisticPurchaseRecordsPage = () => (
  <SiteLayout>
    <SEO title="StatisticPurchaseRecords" />
    <PurchaseRecordsComponent></PurchaseRecordsComponent>
  </SiteLayout>
)

export default StatisticPurchaseRecordsPage;
