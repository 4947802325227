import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { CustomList, customRanderItem } from '../../base_components/lists/custom_list';
import customConfig from '../../../custom_config';
// import ProductsList from '../base_components/products_list';
// import { PurchaseRecordsRequestGet } from './purchase_records_requests';
// import CreatePurchaseRecords from './create_purchase_records';
// import UpdatePurchaseRecords from './modal_collect_statistic';
// import { ItemRendererComponent } from './purchase_records_columns_label_config';
// import { PurchaseRecordsListComponent } from '../base_components/purchase_records_goods_delivery_address/purchase_records_list';
// 页面到底触发事件
// import { LoadMoreButton } from '../base_components/load_more_button_conpoment';
// import { ScrollBottomHandleComponent } from '../base_components/scroll_bottom_handle';
// import DeliverComponent from '../deliver';

// 列表 订单统计
const salePlatforms = customConfig.salePlatforms;
const payMethods = customConfig.payMethods;

const PurchaseRecordsStatisticList = ({ purchaseRecords, queryFactor }) => {
    const [statisticDate, setStatisticDate] = useState('')

    useEffect(() => {
        const sdate = queryFactor.add_time ? queryFactor.add_time : ''
        setStatisticDate(sdate);
    }, [queryFactor])

    const statisticRander = (obj) => {
        // console.log(obj)
        let customer_name = ''
        const customer_info = obj.customer_info
        if (customer_info && typeof customer_info === 'object' && Object.keys(customer_info).includes('name') && Object.keys(customer_info).includes('wx_nick_name')) {
            customer_name = customer_info.name
            customer_name = customer_info.wx_nick_name.length > 0 ? `${customer_name}(${customer_info.wx_nick_name})` : customer_name
        }
        const diopters = `R:${obj.diopter_r}; L:${obj.diopter_l}`
        const showDiopter = diopters === 'R:; L:' ? null : <p>{diopters}</p>
        const item = {
            id: obj.id,
            actions: [],
            img: '',
            avatar: '',
            title: `订单ID: ${obj.id};  顾客: ${customer_name}`,
            description: `下单时间: ${moment(obj.add_time).format('YYYY-MM-DD HH:mm:ss')}`,
            content: (<div>
                <p>商品:{obj.product_title}</p>
                {showDiopter}
                <p>交易平台: {salePlatforms[obj.sale_platform]}; 商品数量: {obj.products_quantity}</p>
                <p>订单金额: {obj.purchase_amount}; 付款方式: {payMethods[obj.pay_platform]}</p>
                <p>成本: {obj.products_cost}</p>
            </div>),
        }
        return customRanderItem(item, 10)
    }

    return (
        <div>
            <CustomList
                header={<h4 style={{color: 'gray'}}>{statisticDate} 订单统计列表</h4>}
                listData={purchaseRecords}
                randerItem={statisticRander}
            ></CustomList>
        </div>
    );
}

PurchaseRecordsStatisticList.defaultProps = {
  purchaseRecords: [],
  queryFactor: {}
}

export default PurchaseRecordsStatisticList;
